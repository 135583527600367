import { AssigneeData } from '@/types/CreditRequest/AssigneeData';
import { ContractFixationItemAssignee } from '@/types/CreditRequest/ContractFixationData';
import { StateCreator } from 'zustand';

import { CreditRequestSlice } from '../../use-credit-request-store';

export type AssigneesSlice = {
  hasAssignees: boolean;
  assigneeList: AssigneeData[];
  assigneeToEdit: AssigneeData | null;
  isAssigneeModalOpen: boolean;
  contractAssignees: ContractFixationItemAssignee[];
  setAssigneeList: (value: AssigneeData[]) => void;
  setHasAssignees: (value: boolean) => void;
  setAssigneeToEdit: (value: AssigneeData | null) => void;
  setAssigneeModalOpen: (value: boolean) => void;
  setContractAssignees: (value: ContractFixationItemAssignee[]) => void;
};

export const assigneesSliceInitialState = {
  hasAssignees: false,
  assigneeList: [],
  assigneeToEdit: null,
  isAssigneeModalOpen: false,
  contractAssignees: [],
};

export const createAssigneesSlice: StateCreator<AssigneesSlice & CreditRequestSlice, [], [], AssigneesSlice> = set => ({
  ...assigneesSliceInitialState,
  setAssigneeList: value => set(state => ({ ...state, assigneeList: value })),
  setHasAssignees: value => set(state => ({ ...state, hasAssignees: value })),
  setAssigneeToEdit: value =>
    set(state => ({
      ...state,
      assigneeToEdit: value,
      isAssigneeModalOpen: value !== null,
    })),
  setAssigneeModalOpen: value => set(state => ({ ...state, isAssigneeModalOpen: value })),
  setContractAssignees: value => set(state => ({ ...state, contractAssignees: value })),
});
