/* eslint-disable no-console */
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { Spin } from './components';
import { AlertDialogProvider } from './components/ui/alert-dialog';

// mascara todos os console.* do projeto
// if (!['local', 'dev', 'development'].includes(import.meta.env.VITE_ENVIRONMENT)) {
//   console.log = (): any => null;
//   console.error = (): any => null;
//   console.debug = (): any => null;
// }
const isClient = import.meta.env.VITE_IS_CLIENT === 'true';

const favicon = document.getElementsByTagName('link')?.[0];
favicon.href = isClient ? `/favicon.ico` : `/company-favicon.png`;

const siteTitle = document.getElementById('siteTitle');
if (siteTitle) siteTitle.innerText = isClient ? import.meta.env.VITE_TITLE_CLIENT : import.meta.env.VITE_TITLE_COMPANY;

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense
    fallback={
      <Spin spinning>
        <div style={{ width: '100vw', height: '100vh', margin: 'auto' }} />
      </Spin>
    }
  >
    <AlertDialogProvider>
      <App />
    </AlertDialogProvider>
  </Suspense>,
);
