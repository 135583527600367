import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearchDollar, FaUpload } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router';

import { ShouldRender } from '@/components/ShouldRender';
import { useAlertDialog } from '@/components/ui/alert-dialog';
import appConfigs from '@/configs/app';
import { useCheckSelectedOperation } from '@/pages/CreditRequest/rules/general/useCheckSelectedOperation';
import { usePrefinModalityRules } from '@/pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { Operations, SubDivisions } from '@/pages/CreditRequest/store/slices/credit-request-slices/division.slice';
import { Input, Form, Modal, Upload, Col, InputNumber, DatePicker, Tooltip } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import moment, { Moment } from 'moment';

import DTOCreditOperation from '../@types/dtos/credit-request/DTOCreditOperation';
import DTOCreditPhase from '../@types/dtos/credit-request/DTOCreditPhase';
import DTOStatus from '../@types/dtos/credit-request/DTOStatus';
import DTOTeam from '../@types/dtos/credit-request/DTOTeam';
import DTOCurrencyType from '../@types/dtos/DTOCurrencyType';
import CreditStatusContextData, { TeamStatusOptionType, TeamStatusRulesTarget } from '../@types/hooks/creditStatus';
import { Button, FormItem, IconWithTooltip, Row, Spin } from '../components';
import { SelectOptionType } from '../components/Select/types';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
  TEAM_STATUS_ADVANCE_PROGRAMMING_CONTRACTS_SOLICITATION_ID,
  TEAM_STATUS_APPROVED_CREDIT_FUTURE_HARVEST_CREDIT,
  TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID,
  TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG,
  TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID,
  TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG,
  TEAM_STATUS_LIMIT_ENABLING_CONTRACT_ID,
  TEAM_STATUS_LIMIT_ENABLING_CONTRACT_SLUG,
  TEAM_STATUS_ON_QUEUE_PREFIN_ID,
} from '../configs/constants';
import creditApi from '../services/api/credit';
import { downloadDocument } from '../services/files';
import { formatValuesToFormData } from '../services/form';
import { getTranslations } from '../services/translations';
import TeamStatusData, { TeamStatusRulesData } from '../types/CreditRequest/DTOTeamStatus';
import { Formatter } from '../utils/Formatter';
import { useAuth } from './auth';
import { useCache } from './cache';
import { useCreditRequest } from './fetch/useCreditRequest';
import { usePtax } from './fetch/usePtax';
import { usePage } from './page';

export type ChangeStatusOptions = { shouldReloadAfterSuccess?: boolean } | undefined;

export interface ChangeCreditRequestStatusDataProps {
  operationId: Operations | null;
  currentStatusId: string;
  newStatusId: string;
  creditRequestId: string;
  creditRequestNumber: number | string;
  currencyTypeSlug: string;
  partialLimitTotal: number;
  requestedAmount: number;
  requestedAmountBarter: number;
  requestedAmountCash: number;
  approvedValue: number;
  approvedValueBarter: number;
  approvedValueCash: number;
  enabledValue: number;
  enabledValueBarter: number;
  enabledValueCash: number;
  modalityId: string;
  datasource: string;
  subDivisionId: SubDivisions | null;
  providerSapCode?: string;
  isImported?: boolean;
  limitExpirationDate?: Moment | string;
}

const initialChangeCreditRequestStatusData = {
  operationId: null,
  currentStatusId: '',
  newStatusId: '',
  creditRequestId: '',
  creditRequestNumber: '',
  currencyTypeSlug: '',
  partialLimitTotal: 0,
  approvedValue: 0,
  approvedValueBarter: 0,
  approvedValueCash: 0,
  requestedAmount: 0,
  requestedAmountBarter: 0,
  requestedAmountCash: 0,
  enabledValue: 0,
  enabledValueBarter: 0,
  enabledValueCash: 0,
  modalityId: '',
  subDivisionId: null,
  providerSapCode: '',
  isImported: false,
  datasource: '',
} as ChangeCreditRequestStatusDataProps;

const CreditStatusContext = createContext<CreditStatusContextData>({} as CreditStatusContextData);

const CreditStatusProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [formModalStatus] = Form.useForm();
  const { getSubsidiarySapCode, isFetchingSubsidiarySapCode } = useCreditRequest();
  const navigate = useNavigate();
  const { alert } = useAlertDialog();

  const { user } = useAuth();
  const { team, credit_status, team_credit_status, team_credit_status_phase, operation, position, currency_type } =
    useCache();
  const { alertStatus } = usePage();
  const { getLastPtax, isFetching: isFetchingPtax } = usePtax();

  const [targetStatus, setTargetStatus] = useState<TeamStatusData>({} as TeamStatusData);
  const [changeCreditRequestStatusData, setChangeCreditRequestStatusData] =
    useState<ChangeCreditRequestStatusDataProps>(initialChangeCreditRequestStatusData);
  const [commentStatus, setCommentStatus] = useState<string>('');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [teamStatusHasChanged, setTeamStatusHasChanged] = useState<boolean>(false);
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [documentLoading, setDocumentLoading] = useState('');
  const [documentRemoving, setDocumentRemoving] = useState('');
  const [_, setSubsidiarySapCode] = useState<string>('');
  const [shouldBlockApprovedValue, setShouldBlockApprovedValue] = useState<boolean>(false);
  const [shouldReloadAfterSuccess, setShouldReloadAfterSuccess] = useState<boolean>(false);

  const { getShouldShowLimitBarterInputs, getShouldShowLimitCashInputs, getShouldShowLimitInputs } =
    usePrefinModalityRules();

  const { checkIsPrefin, checkIsPrefinSugarMills } = useCheckSelectedOperation();

  const shouldShowLimitBarterInputs = useMemo(
    () =>
      changeCreditRequestStatusData.subDivisionId
        ? getShouldShowLimitBarterInputs(
            changeCreditRequestStatusData.modalityId,
            changeCreditRequestStatusData.subDivisionId,
          )
        : false,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const shouldShowLimitCashInputs = useMemo(
    () =>
      changeCreditRequestStatusData.subDivisionId
        ? getShouldShowLimitCashInputs(
            changeCreditRequestStatusData.modalityId,
            changeCreditRequestStatusData.subDivisionId,
          )
        : null,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const shouldShowLimitInputs = useMemo(
    () =>
      changeCreditRequestStatusData.operationId
        ? checkIsPrefin(changeCreditRequestStatusData.operationId) ||
          checkIsPrefinSugarMills(changeCreditRequestStatusData.operationId)
          ? changeCreditRequestStatusData.subDivisionId
            ? getShouldShowLimitInputs(
                changeCreditRequestStatusData.modalityId,
                changeCreditRequestStatusData.subDivisionId,
              )
            : false
          : true
        : false,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const getTeam = useCallback(
    (id: string, likeOptions = false) => {
      const findTeam = team.find((teamItem: DTOTeam) => teamItem.id === id);

      if (findTeam) {
        const teamItem = { ...findTeam, translations: findTeam.translations };

        if (likeOptions === true) {
          return {
            key: teamItem.id,
            label: getTranslations(teamItem, 'title', i18n.language, 'name'),
            value: teamItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamItem as DTOTeam;
      }

      return null;
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getTeamBySlug = useCallback(
    (slug: string, likeOptions = false) => {
      const findTeam = team.find((teamItem: DTOTeam) => teamItem.slug === slug);

      if (findTeam) {
        const teamItem = { ...findTeam, translations: findTeam.translations };

        if (likeOptions === true) {
          return {
            key: teamItem.id,
            label: getTranslations(teamItem, 'title', i18n.language, 'name'),
            value: teamItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamItem as DTOTeam;
      }

      return null;
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getCurrencyType = useCallback(
    (id: string, likeOptions = false) => {
      const findCurrency = currency_type.find((currencyItem: DTOCurrencyType) => currencyItem.id === id);

      if (findCurrency) {
        const currencyItem = { ...findCurrency, translations: findCurrency.translations };

        if (likeOptions === true) {
          return {
            key: currencyItem.id,
            label: currencyItem.slug,
            value: currencyItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return currencyItem as DTOCurrencyType;
      }

      return null;
    },
    // eslint-disable-next-line
    [currency_type],
  );

  const getOperation = useCallback(
    (id: string, likeOptions = false) => {
      const findOperation = operation.find((operationItem: DTOCreditOperation) => operationItem.id === id);

      if (findOperation) {
        const operationItem = { ...findOperation, translations: findOperation.translations };

        if (likeOptions === true) {
          return {
            key: operationItem.id,
            label: getTranslations(operationItem, 'title', i18n.language, 'name'),
            value: operationItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return operationItem as DTOCreditOperation;
      }

      return null;
    },
    // eslint-disable-next-line
    [operation, getTranslations, i18n],
  );

  const getStatus = useCallback(
    (id: string, likeOptions = false) => {
      const findCreditStatus = credit_status.find((creditStatusItem: DTOStatus) => creditStatusItem.id === id);

      if (findCreditStatus) {
        const status = { ...findCreditStatus, translations: findCreditStatus.translations };

        if (likeOptions === true) {
          return {
            key: status.id,
            label: getTranslations(status, 'title', i18n.language, 'name'),
            value: status.id,
            disabled: true,
          } as SelectOptionType;
        }

        return status as DTOStatus;
      }

      return null;
    },
    // eslint-disable-next-line
    [credit_status, getTranslations, i18n],
  );

  const getTeamStatus = useCallback(
    (id: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status?.find((teamStatusItem: TeamStatusData) => teamStatusItem.id === id);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as TeamStatusData;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getTeamStatusBySlug = useCallback(
    (slug: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status?.find((teamStatusItem: TeamStatusData) => teamStatusItem.slug === slug);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as TeamStatusData;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeams = useCallback(
    (likeOptions = false) => {
      const listStatus = team;

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: DTOTeam) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));
        return listOptions as TeamStatusOptionType[];
      }
      return listStatus as DTOTeam[];
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getTeamsByPhaseId = useCallback(
    (phaseId: string) => {
      // eslint-disable-next-line prefer-const
      let teamFiltered: string[] = [];
      team_credit_status.forEach((item: TeamStatusData) => {
        if (item.phase_id === phaseId) {
          teamFiltered.push(item.team_id);
        }
      });

      const result = getListOfTeams() as any;
      return result
        .filter((item: DTOTeam) => teamFiltered.includes(item.id ?? ''))
        .filter((t: any) => team.find(team => team.id === t.id));
    },
    // eslint-disable-next-line
    [getListOfTeams, team_credit_status, team],
  );

  const getListOfTeamStatusOrderedByPhase = useCallback(
    (likeOptions = false, phaseId: string) => {
      let listStatus = team_credit_status;

      team_credit_status.forEach((item: TeamStatusData) => {
        if (item.phase_id === phaseId) {
          listStatus.push(item);
        }
      });

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamsOrdered = useCallback(
    (likeOptions = false) => {
      // eslint-disable-next-line func-names
      const listTeams = team?.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listTeams.map((item: DTOTeam) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listTeams as DTOTeam[];
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getPhase = useCallback(
    (id: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status_phase.find((phase: DTOCreditPhase) => phase.id === id);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as DTOCreditPhase;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status_phase, getTranslations, i18n],
  );

  const getListOfPhasesOrdered = useCallback(
    (likeOptions = false) => {
      // eslint-disable-next-line func-names
      const listPhases = team_credit_status_phase?.sort(function (a, b) {
        return Number(a.order) - Number(b.order);
      });

      if (likeOptions === true) {
        const listOptions = listPhases.map((item: DTOCreditPhase) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listPhases as DTOCreditPhase[];
    },
    // eslint-disable-next-line
    [team_credit_status_phase, getTranslations, i18n],
  );

  const getListOfTeamStatus = useCallback(
    (likeOptions = false) => {
      const listStatus = team_credit_status;

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamStatusOrdered = useCallback(
    (likeOptions = false, teamId = null, phaseId = null) => {
      let listStatus = team_credit_status;

      if (teamId !== null && phaseId !== null) {
        listStatus = team_credit_status.filter(
          teamStatus => teamStatus.team_id === teamId && teamStatus.phase_id === phaseId,
        );
      }

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamStatusOrderedTargetRules = useCallback(
    (teamId: string | null = null) => {
      let listStatus = team_credit_status;

      if (teamId !== null) {
        listStatus = team_credit_status.filter(teamStatus => teamStatus.team_id === teamId);
      }

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      listStatus = listStatus.map(item => {
        const rules = item.rules_target;
        let option: TeamStatusRulesTarget = { ...item, target_team_status: [] };

        rules?.forEach(ruleItem => {
          const foundOperation = operation.find(operationItem => operationItem.id === ruleItem.operation_id);
          const teamStatus = getTeamStatus(ruleItem.team_credit_status_target_id) as TeamStatusData;

          if (!ruleItem.position_id && foundOperation) {
            option = {
              ...option,
              target_team_status: [
                ...(option.target_team_status ?? []),
                {
                  ...teamStatus,
                  operation: foundOperation,
                },
              ],
            };
          }

          if (user.position_id === ruleItem.position_id && foundOperation) {
            const findPosition = position.find(positionItem => positionItem.id === ruleItem.position_id);
            option = {
              ...option,
              target_team_status: [
                ...(option.target_team_status ?? []),
                {
                  ...teamStatus,
                  operation: foundOperation,
                  position: findPosition,
                },
              ],
            };
          }
        });

        return option;
      });

      return listStatus as TeamStatusRulesTarget[];
    },
    // eslint-disable-next-line
    [team_credit_status, i18n],
  );

  const getListOfNextTeamStatus = useCallback(
    (
      currentId: string,
      operationId: string,
      positionId: string | null = null,
      likeOptions = false,
      includeCurrent = true,
    ) => {
      const findTeamStatus = team_credit_status?.find(teamStatus => teamStatus.id === currentId);

      if (findTeamStatus) {
        const rules = findTeamStatus.rules_target;
        const listStatusIds = rules
          .filter((rule: TeamStatusRulesData) => {
            return rule.operation_id === operationId && (rule.position_id === positionId || rule.position_id === null);
          })
          .map((rule: TeamStatusRulesData) => rule.team_credit_status_target_id);

        if (listStatusIds.length > 0) {
          const listStatus = team_credit_status
            .filter(teamStatus => (listStatusIds.indexOf(teamStatus.id ?? '') > -1 ? teamStatus : null))
            .filter(teamStatus => teamStatus != null);

          if (likeOptions === true) {
            const listOptions = listStatus.map((teamStatus: TeamStatusData) => ({
              key: teamStatus.id,
              label: getTranslations(teamStatus, 'description', i18n.language, 'name'),
              value: teamStatus.id,
            }));

            if (includeCurrent === false) {
              return listOptions as SelectOptionType[];
            }
            return [getTeamStatus(currentId, true), ...listOptions] as SelectOptionType[];
          }

          if (includeCurrent === false) {
            return listStatus as TeamStatusData[];
          }
          return [getTeamStatus(currentId), ...listStatus] as TeamStatusData[];
        }
      }

      return [];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  async function handleChangeStatusCreditRequest(changeStatusData: ChangeCreditRequestStatusDataProps) {
    setChangeCreditRequestStatusData({
      ...changeStatusData,
      partialLimitTotal: changeStatusData.partialLimitTotal,
    });
    setModalVisible(true);
    const status = getTeamStatus(changeStatusData.newStatusId) as TeamStatusData;
    if (!status) {
      console.error('Status not found id:', changeStatusData.newStatusId);
      return alert({
        title: t('error'),
        type: 'error',
        description: t('selected-status-not-found'),
      });
    }
    setTargetStatus(status);
  }

  function handleModalClose() {
    setModalVisible(false);
    setModalLoading(false);
    setCommentStatus('');
    formModalStatus.resetFields();
    setDocumentList([]);
    setDocumentLoading('');
    setDocumentRemoving('');
    setShouldReloadAfterSuccess(false);
  }

  const fetchSubsidiarySapCode = async () => {
    if (!changeCreditRequestStatusData.providerSapCode) return;
    const response = await getSubsidiarySapCode(changeCreditRequestStatusData.providerSapCode);
    setSubsidiarySapCode(response?.code);
    formModalStatus.setFieldsValue({
      code_bp: response?.code ?? changeCreditRequestStatusData.providerSapCode,
    });
  };

  const fieldAmount = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let result = [];
    if (targetStatus) {
      if (
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG &&
          (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_MTM_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_INTERN_MARKET_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_FERTILIZER_SUPPLIERS_ID)) ||
        (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
          targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID)
      ) {
        result.push('enable_limit');
      }
      if (
        targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
        targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID ||
        targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID ||
        targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG ||
        (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
          targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID) ||
        (targetStatus.id === TEAM_STATUS_APPROVED_CREDIT_FUTURE_HARVEST_CREDIT &&
          changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID)
      ) {
        if (changeCreditRequestStatusData.approvedValue) {
          setShouldBlockApprovedValue(true);
          formModalStatus.setFieldsValue({ approved_value: changeCreditRequestStatusData.approvedValue });
        }
        result.push('approved_value');
      }

      if (
        changeCreditRequestStatusData?.datasource?.toLowerCase() !== 'datasul' &&
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID ||
          targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG ||
          targetStatus.id === TEAM_STATUS_ADVANCE_PROGRAMMING_CONTRACTS_SOLICITATION_ID ||
          (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
            targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID))
      ) {
        result.push('code_bp');
        fetchSubsidiarySapCode();
      }

      if (
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID) &&
        changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID
      ) {
        result.push('limit_expiration_date');
        formModalStatus.setFieldsValue({
          limit_expiration_date:
            typeof changeCreditRequestStatusData.limitExpirationDate === 'string'
              ? changeCreditRequestStatusData.limitExpirationDate
                ? moment(changeCreditRequestStatusData.limitExpirationDate)
                : ''
              : changeCreditRequestStatusData.limitExpirationDate,
        });
      }

      if (
        targetStatus.slug === TEAM_STATUS_LIMIT_ENABLING_CONTRACT_SLUG ||
        targetStatus.id === TEAM_STATUS_LIMIT_ENABLING_CONTRACT_ID
      ) {
        result.push('json_data.amount_paid');
      }
    }

    return result;
    // eslint-disable-next-line
  }, [targetStatus]);

  async function handleModalChange() {
    setModalLoading(true);

    formModalStatus
      .validateFields()
      .then(async (formValues: any) => {
        formValues['documents[]'] = documentList;

        let formData = new FormData();
        formData = formatValuesToFormData(formValues, formData, ['documents[]'], ['credit_due_date']);

        if (fieldAmount !== null) {
          if (fieldAmount.includes('approved_value')) {
            // formData.append('ptax', formValues.ptax);
            if (shouldShowLimitInputs) formData.append('approved_value', formValues.approved_value);
            // if (shouldShowLimitBarterInputs) formData.append('approved_value_barter', formValues.approved_value_barter);
            // if (shouldShowLimitCashInputs) formData.append('approved_value_cash', formValues.approved_value_cash);
          }

          if (fieldAmount.includes('enable_limit')) {
            formData.append('credit_due_date', moment(formValues.credit_due_date).format(appConfigs.formatApiDate));
          }

          if (fieldAmount.includes('json_data.amount_paid')) {
            formData.append('enabled_value', formValues['json_data.amount_paid']);
          }
        }

        formData.append('language', i18n.language);
        formData.append('team_status_id', changeCreditRequestStatusData.newStatusId);
        formData.append('_method', 'PATCH');

        await creditApi.requests
          .updateStatus(changeCreditRequestStatusData.creditRequestId, formData)
          .then(() => {
            alertStatus(t('hooks.creditStatus.modal-status-success'));
            setTeamStatusHasChanged(true);
            if (shouldReloadAfterSuccess) {
              if (
                changeCreditRequestStatusData.newStatusId &&
                changeCreditRequestStatusData.operationId !== OPERATION_PREPAY_ID &&
                CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE.includes(changeCreditRequestStatusData.newStatusId)
              ) {
                navigate(`/credit-request/edit/${changeCreditRequestStatusData.creditRequestId}`);
              } else navigate(`/credit-request/view/${changeCreditRequestStatusData.creditRequestId}`);
              setShouldReloadAfterSuccess(false);
            }
          })
          .catch(err => alertStatus(err || t('hooks.creditStatus.modal-status-unknown-error'), 'error'))
          .finally(() => {
            handleModalClose();
          });
      })
      .catch(err => {
        console.log(err);
        setModalLoading(false);
      });

    return false;
  }

  const isInvalidTargetStatusAttachmentRule = useMemo(() => {
    if (changeCreditRequestStatusData.currentStatusId) {
      const fromTeamStatus = getTeamStatus(changeCreditRequestStatusData.currentStatusId, false) as TeamStatusData;
      const rules = fromTeamStatus.rules_target;
      const listTeamStatus = rules.filter(
        (rule: TeamStatusRulesData) =>
          rule.operation_id === changeCreditRequestStatusData.operationId &&
          rule.team_credit_status_target_id === targetStatus?.id,
      );
      let targetIsAttachmentRequired = false;
      if (listTeamStatus.length > 0) {
        targetIsAttachmentRequired = Boolean(listTeamStatus[0].is_attachment_required);
      }
      return targetIsAttachmentRequired === true && documentList.length === 0;
    }

    return false;
    // eslint-disable-next-line
  }, [
    documentList,
    targetStatus,
    changeCreditRequestStatusData.currentStatusId,
    changeCreditRequestStatusData.operationId,
  ]);

  async function handleGetPtax() {
    const lastPtax = await getLastPtax();
    formModalStatus.setFieldsValue({ ptax: lastPtax });
  }

  useEffect(() => {
    formModalStatus.setFieldsValue({ 'documents[]': documentList });
    // eslint-disable-next-line
  }, [formModalStatus, documentList]);
  return (
    <CreditStatusContext.Provider
      value={{
        shouldReloadAfterSuccess,
        setShouldReloadAfterSuccess,
        teamStatusHasChanged,
        setTeamStatusHasChanged,
        handleChangeStatusCreditRequest,
        getListOfNextTeamStatus,
        getTeam,
        getCurrencyType,
        getTeamBySlug,
        getOperation,
        getStatus,
        getTeamStatus,
        getTeamsByPhaseId,
        getListOfTeamStatusOrderedByPhase,
        getListOfTeams,
        getListOfTeamsOrdered,
        getPhase,
        getListOfPhasesOrdered,
        getListOfTeamStatus,
        getListOfTeamStatusOrdered,
        getListOfTeamStatusOrderedTargetRules,
        getTeamStatusBySlug,
      }}
    >
      <>
        {children}

        <Modal
          visible={modalVisible}
          title={t('hooks.creditStatus.modal-status-title')}
          className="modal-with-custom-footer"
          closeIcon={<MdClose onClick={() => handleModalClose()} />}
          footer={
            <>
              <div className="buttons">
                <Button status="secondary" onClick={() => handleModalClose()} disabled={modalLoading}>
                  {t('hooks.creditStatus.modal-status-buttons.cancel')}
                </Button>

                <Button
                  status="primary"
                  onClick={() => handleModalChange()}
                  loading={modalLoading}
                  disabled={modalLoading || isInvalidTargetStatusAttachmentRule}
                >
                  {t('hooks.creditStatus.modal-status-buttons.save')}
                </Button>
              </div>
            </>
          }
        >
          <Spin spinning={modalLoading}>
            <p>
              {t('hooks.creditStatus.modal-status-description-next-status').replace(
                '{credit_request}',
                changeCreditRequestStatusData.creditRequestNumber.toString(),
              )}
              {'  '}
              <strong>{targetStatus?.translations ? targetStatus?.translations[i18n.language].title : ''}</strong>
            </p>
            <br />
            <p>{t('hooks.creditStatus.modal-status-note')}</p>
            <br />
            {changeCreditRequestStatusData.requestedAmount > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-requested_amount')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.requestedAmount,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.requestedAmountBarter > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-requested_amount_barter')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.requestedAmountBarter,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.requestedAmountCash > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-requested_amount_cash')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.requestedAmountCash,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.approvedValue > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-approved-value')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.approvedValue,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.approvedValueBarter > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-approved-value-barter')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.approvedValueBarter,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.approvedValueCash > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-approved-value-cash')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.approvedValueCash,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {changeCreditRequestStatusData.enabledValue > 0 && (
              <p>
                {t('hooks.creditStatus.modal-status-limit-enabled')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.enabledValue,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>{' '}
              </p>
            )}
            {fieldAmount.includes('partial_limit') && (
              <p>
                {t('hooks.creditStatus.modal-status-partial-limit')}:{' '}
                <b>
                  {Formatter.money(
                    changeCreditRequestStatusData.enabledValue,
                    2,
                    changeCreditRequestStatusData.currencyTypeSlug,
                  )}
                </b>
              </p>
            )}
            <Form form={formModalStatus} name="status-change-form" className="form-secondary">
              <FormItem hidden name="team_status_id" initialValue={changeCreditRequestStatusData.newStatusId}>
                <Input />
              </FormItem>
              <FormItem
                label={t('hooks.creditStatus.modal-status-comments-field')}
                name="comments"
                extra={`${commentStatus.length}/500`}
              >
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  maxLength={500}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.target;
                    setCommentStatus(value);
                  }}
                />
              </FormItem>

              {fieldAmount.includes('enable_limit') ? (
                <FormItem
                  label={t('hooks.creditStatus.modal-status-credit-limit-date-field')}
                  name="credit_due_date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    locale={ptBR}
                    mode="date"
                    format={appConfigs.formatDate}
                    disabledDate={date => date.isBefore(moment().subtract(1, 'days'))}
                    disabled={changeCreditRequestStatusData.isImported}
                  />
                </FormItem>
              ) : null}

              {fieldAmount.includes('limit_expiration_date') ? (
                <>
                  <FormItem
                    label={t('hooks.creditStatus.modal-status-credit-limit-date-field_prepay')}
                    name="limit_expiration_date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} disabled />
                  </FormItem>

                  <FormItem label="PTAX" name="ptax" rules={[{ required: true }]}>
                    <InputNumber<string>
                      min="0.00001"
                      maxLength={8}
                      formatter={(value: string | undefined) => Formatter.money(value, 5, null)}
                      parser={(value: string | undefined) => Formatter.moneyParser(value, 5)}
                      disabled
                      style={{ width: '100%' }}
                      addonAfter={
                        <span style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                          <Tooltip title={<span>{t('pages.credit-request.form.get_ptax')}</span>}>
                            <Button
                              type="default"
                              size="small"
                              onClick={handleGetPtax}
                              disabled={isFetchingPtax}
                              loading={isFetchingPtax}
                            >
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                <FaSearchDollar style={{ margin: 'none' }} />
                              </span>
                            </Button>
                          </Tooltip>
                        </span>
                      }
                    />
                  </FormItem>
                </>
              ) : null}

              {fieldAmount.includes('code_bp') && (
                <Spin spinning={isFetchingSubsidiarySapCode}>
                  <FormItem
                    label={t('hooks.creditStatus.modal-status-bp-code-field')}
                    name="code_bp"
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={255} disabled={changeCreditRequestStatusData.isImported} />
                  </FormItem>
                </Spin>
              )}

              {fieldAmount.includes('approved_value') && (
                <>
                  <ShouldRender
                    condition={
                      changeCreditRequestStatusData.operationId === OPERATION_INTERN_MARKET_ID ||
                      changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID ||
                      changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID
                    }
                  >
                    <FormItem label="PTAX" name="ptax" rules={[{ required: true }]}>
                      <InputNumber<string>
                        min="0.00001"
                        maxLength={8}
                        formatter={(value: string | undefined) => Formatter.money(value, 5, null)}
                        parser={(value: string | undefined) => Formatter.moneyParser(value, 5)}
                        disabled
                        style={{ width: '100%' }}
                        addonAfter={
                          <span style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                            <Tooltip title={<span>{t('pages.credit-request.form.get_ptax')}</span>}>
                              <Button
                                type="default"
                                size="small"
                                onClick={handleGetPtax}
                                disabled={isFetchingPtax}
                                loading={isFetchingPtax}
                              >
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <FaSearchDollar style={{ margin: 'none' }} />
                                </span>
                              </Button>
                            </Tooltip>
                          </span>
                        }
                      />
                    </FormItem>
                  </ShouldRender>
                  <ShouldRender condition={shouldShowLimitInputs}>
                    <FormItem
                      label={`${
                        changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID
                          ? t('hooks.creditStatus.modal-status-approved-value-field_prepay')
                          : t('hooks.creditStatus.modal-status-approved-value-field')
                      }${changeCreditRequestStatusData.operationId === OPERATION_MTM_ID ? ' (US$)' : ''}`}
                      name="approved_value"
                      rules={[
                        { required: true },
                        () => ({
                          validator(rule: any, value: any) {
                            return changeCreditRequestStatusData.requestedAmount < value &&
                              changeCreditRequestStatusData.operationId !== OPERATION_MTM_ID
                              ? Promise.reject(t('pages.credit-request.errors.approved_greater_requested'))
                              : Promise.resolve();
                          },
                        }),
                        {
                          validator: async (_, value: any) => {
                            if (value <= 0) {
                              return Promise.reject(new Error('Digite um valor > 0'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber<string>
                        formatter={(value: string | undefined) => Formatter.money(value)}
                        parser={(value: string | undefined) => Formatter.moneyParser(value)}
                        min="0"
                        minLength={0}
                        disabled={shouldBlockApprovedValue || changeCreditRequestStatusData.isImported}
                      />
                    </FormItem>
                  </ShouldRender>

                  <ShouldRender condition={shouldShowLimitBarterInputs}>
                    <FormItem
                      label={t('hooks.creditStatus.modal-status-approved-value-barter-field')}
                      name="approved_value_barter"
                      rules={[
                        { required: true },
                        () => ({
                          validator(rule: any, value: any) {
                            return changeCreditRequestStatusData.requestedAmountBarter < value
                              ? Promise.reject(t('pages.credit-request.errors.approved_barter_greater_requested'))
                              : Promise.resolve();
                          },
                        }),
                        {
                          validator: async (_, value: any) => {
                            if (value <= 0) {
                              return Promise.reject(new Error('Digite um valor > 0'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber<string>
                        formatter={(value: string | undefined) => Formatter.money(value)}
                        parser={(value: string | undefined) => Formatter.moneyParser(value)}
                        min="0"
                        minLength={0}
                      />
                    </FormItem>
                  </ShouldRender>

                  <ShouldRender condition={!!shouldShowLimitCashInputs}>
                    <FormItem
                      label={t('hooks.creditStatus.modal-status-approved-value-cash-field')}
                      name="approved_value_cash"
                      rules={[
                        { required: true },
                        () => ({
                          validator(rule: any, value: any) {
                            return changeCreditRequestStatusData.requestedAmountCash < value
                              ? Promise.reject(t('pages.credit-request.errors.approved_cash_greater_requested'))
                              : Promise.resolve();
                          },
                        }),
                        {
                          validator: async (_, value: any) => {
                            if (value <= 0) {
                              return Promise.reject(new Error('Digite um valor > 0'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber<string>
                        formatter={(value: string | undefined) => Formatter.money(value)}
                        parser={(value: string | undefined) => Formatter.moneyParser(value)}
                        min="0"
                        minLength={0}
                      />
                    </FormItem>
                  </ShouldRender>
                </>
              )}

              {fieldAmount.includes('json_data.amount_paid') && (
                <FormItem
                  label={t('hooks.creditStatus.modal-status-enabled-field')}
                  name="json_data.amount_paid"
                  rules={[
                    { required: true },
                    () => ({
                      validator(rule: any, value: any) {
                        return changeCreditRequestStatusData.approvedValue < value
                          ? Promise.reject(t('pages.credit-request.errors.approved_value'))
                          : Promise.resolve();
                      },
                    }),
                    {
                      validator: async (_, value: any) => {
                        if (value <= 0) {
                          return Promise.reject(new Error('Digite um valor > 0'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber<string>
                    formatter={(value: string | undefined) => Formatter.money(value)}
                    parser={(value: string | undefined) => Formatter.moneyParser(value)}
                  />
                </FormItem>
              )}

              <FormItem
                extra={
                  isInvalidTargetStatusAttachmentRule ? t('hooks.creditStatus.modal-status-required-attachment') : ''
                }
                validateStatus={isInvalidTargetStatusAttachmentRule ? 'error' : ''}
              >
                <Upload
                  multiple
                  showUploadList={false}
                  customRequest={async (info: any) => {
                    setDocumentList(documentListState => [...documentListState, info.file]);
                  }}
                >
                  <Button status="primary-outline" icon={<FaUpload />}>
                    {t('hooks.creditStatus.modal-status-select-files')}
                  </Button>
                </Upload>
              </FormItem>

              <Form.List name="documents[]">
                {fields => (
                  <>
                    {fields.map((field: any) => (
                      <>
                        <Row gutter={[26, 26]} key={`files-item-${field.key}-${documentList[field.name]?.name}`}>
                          <FormItem name={[field.name, 'original']} hidden>
                            <Input />
                          </FormItem>
                          <FormItem name={[field.name, 'mime']} hidden>
                            <Input />
                          </FormItem>
                          <FormItem name={[field.name, 'image']} hidden>
                            <Input />
                          </FormItem>
                          <FormItem name={[field.name, 'name']} hidden>
                            <Input />
                          </FormItem>

                          <Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
                            <div>{documentList[field.name] ? documentList[field.name].name : ''}</div>
                          </Col>

                          <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                            <div className="action-buttons">
                              <IconWithTooltip
                                action="download"
                                title={t('hooks.creditStatus.modal-status-icon-download')}
                                loading={documentLoading === field.name}
                                onClick={async () => {
                                  downloadDocument(documentList[field.name].image, documentList[field.name].name);
                                  setDocumentLoading('');
                                }}
                              />
                              <IconWithTooltip
                                action="destroy"
                                title={t('hooks.creditStatus.modal-status-icon-remove')}
                                loading={documentRemoving === field.name}
                                onClick={async () => {
                                  setDocumentRemoving(field.name);
                                  setDocumentList(documentList.filter((doc: any, index: any) => index !== field.name));
                                  setDocumentRemoving('');
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          </Spin>
        </Modal>
      </>
    </CreditStatusContext.Provider>
  );
};

function useCreditStatus(): CreditStatusContextData {
  return useContext(CreditStatusContext);
}

export { CreditStatusProvider, useCreditStatus };
