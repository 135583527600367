import React from 'react';
import { RouteProps as ReactDOMRouteProps, Navigate } from 'react-router-dom';

import { Spin } from '../components';
import { AUTH_PASSWORD_EXPIRES_ENABLED, EXPIRED_PAGE_URL, HOME_PAGE_URL, LOGIN_PAGE_URL } from '../configs/constants';
import { useAuth } from '../hooks/auth';
import { Page, PrivatePage } from '../partials';

type RouteProps = ReactDOMRouteProps & {
  isPrivate?: boolean;
  ifAuthRedirect?: boolean;
  component: React.ComponentType;
};

// Rota privada/Usuário está autenticado
// true/true = OK
// true/false = Redirecionar o usuário para login (SignIn)
// false/true = Redirecionar o usuário para Dashboard
// false/false = OK

const Route: React.FC<RouteProps> = ({ isPrivate = false, ifAuthRedirect = true, component: Component, ...rest }) => {
  const { loadingData, user, clearData, isExpiredToken, isExpiredPassword, getToken, getTokenType, isExternalUser } =
    useAuth();

  // const [getPath] = useCurrentLocation(isPrivate, user);

  // if (isPrivate && user === undefined) {
  //   useCurrentLocation(pathname);
  // }

  if (!loadingData) {
    if (isExpiredToken?.()) {
      clearData();
      return <Navigate to={LOGIN_PAGE_URL} />;
    }

    /**
     * se for acesso external (ex.: acesso via microsoft/AD) não verifica expiração de senha, pois,
     * não é responsabilidade do agrodata
     */
    if (
      getToken() &&
      getTokenType() !== 'authorization' &&
      isExpiredPassword() &&
      rest.path !== EXPIRED_PAGE_URL &&
      !isExternalUser() &&
      AUTH_PASSWORD_EXPIRES_ENABLED
    ) {
      return <Navigate to={EXPIRED_PAGE_URL} />;
    }

    if (isPrivate === !!user || !ifAuthRedirect) {
      return isPrivate && ifAuthRedirect ? (
        <PrivatePage>
          <Component />
        </PrivatePage>
      ) : (
        <Page>
          <Component />
        </Page>
      );
    }
    return (
      <Navigate
        to={{
          pathname: isPrivate ? LOGIN_PAGE_URL : HOME_PAGE_URL,
        }}
      />
    );
  }
  return (
    <Spin spinning={loadingData}>
      <div style={{ width: '100vw', height: '100vh', margin: 'auto' }} />
    </Spin>
  );
};

export default Route;
